export const HOME = "/";
export const MULTIMEDIA = "/multimedia";
export const PHOTOS = "/multimedia/photos";
export const PHOTOS_PUBLIC = "/multimedia/photos/public";
export const PHOTOS_PRIVATE = "/multimedia/photos/private";
export const VIDEOS = "/multimedia/videos";
export const AUDIOS = "/multimedia/audios";
export const PROFILE = "/profile";
export const INBOX = "/profile/inbox";
export const SERVICES = "/profile/services";
export const SCHEDULE = "/schedule";
export const THEME = "/theme";
export const SETTINGS = "/settings";
export const TEMPLATE = "/template";
export const BLOG_HOME = "/blog";
export const BLOG_PAGE = "/blog/:id";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const NOT_FOUND = "/*";
